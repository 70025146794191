import * as React from 'react';
import { useNavigate } from 'react-router';
import PreviewIcon from '@mui/icons-material/Preview';
import CloseIcon from '@mui/icons-material/Close';
import { Badge, Box, Button, Drawer, Grid, IconButton, Typography } from '@mui/material';

import useAuth from 'hooks/useAuth';
import {
  useFetchOrganizationsWithSlotAndJpDiscrepanciesQuery,
  useOrganizationsDiscrepanciesBetweenSlotsAndActiveJobPositions
} from 'pages/showOrganization/use-slot-jp-discrepancies-queries';

interface IGlobalSlotUsageStatusProps {}

const GlobalSlotUsageStatus: React.FunctionComponent<IGlobalSlotUsageStatusProps> = (props) => {
  const { user } = useAuth();
  const [isDrawerOpen, toggleDrawer] = React.useState(false);
  const { isLoading } = useFetchOrganizationsWithSlotAndJpDiscrepanciesQuery({
    csmId: user?.data?.id
  });
  const organizations = useOrganizationsDiscrepanciesBetweenSlotsAndActiveJobPositions({
    csmId: user?.data?.id
  });
  const navigate = useNavigate();
  return (
    <>
      <IconButton onClick={() => toggleDrawer((p) => !p)} sx={{ color: '#637381' }}>
        <Badge badgeContent={organizations?.length || 0} color="warning">
          <PreviewIcon />
        </Badge>
      </IconButton>
      <Drawer
        sx={{ width: '512px' }}
        anchor="right"
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <Box
          sx={{
            paddingX: '1.5rem',
            paddingY: '2rem',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
            borderBottom: '1px solid #e0e0e0',
            position: 'sticky',
            top: 0,
            bgcolor: 'white',
            zIndex: 99
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="h6">Discrepanze degli slot</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => toggleDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '64px',
            paddingY: '54px',
            paddingX: '1.5rem'
          }}
        >
          {organizations?.map((org, index) => (
            <Grid container key={index} alignItems="center" spacing={2}>
              <Grid item xs>
                <Typography variant="subtitle2" color="textSecondary">
                  {org.organization_name}
                </Typography>
                <Typography variant="subtitle2" color="textPrimary">
                  {org.active_slots} slot attivi / {org.active_job_positions} posizioni lavorative
                  aperte
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    toggleDrawer(false);
                    navigate(`/organizations/${org.organization_id}`);
                  }}
                  variant="outlined"
                  color="inherit"
                  size="medium"
                >
                  Dettagli
                </Button>
              </Grid>
            </Grid>
          ))}
          {organizations?.length === 0 && (
            <Typography variant="subtitle2" color="textSecondary">
              Nessuna discrepanza trovata
            </Typography>
          )}
          {isLoading && (
            <Typography variant="subtitle2" color="textSecondary">
              Caricamento...
            </Typography>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default GlobalSlotUsageStatus;
